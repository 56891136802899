html, body {
  background: #FFF5EE;
  font-family: Arial, sans-serif;
}

.upper-container {
  background-color: #FFF5EE;
  }

  @media screen and (min-width: 768px) {
    .upper-container {
      background-image: url("/public/assets/misc_images/chessboard_partial.svg");
      background-position: right;
      background-repeat: no-repeat;
    }  
  } 

  .lower-container {
    background-color: #f5f5f5;
    }

 h2, h3 {
  text-align: center;
  font-family: 'Lato', Arial, sans-serif;
  font-size: 130%;
  color: #2074d4;
}

h1 {
  text-align: center;
  font-family: 'Lato', Arial, sans-serif;
  font-size: 175%;
  font-weight: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
.opaque-tooltip .MuiTooltip-tooltip {
  opacity: 1;
  background-color: red;
}

.select-timeframe-text {
  font-size: 0.8rem; 
  text-align: right;
  margin-top: 3px;
}

.small-select .MuiMenuItem-root {
  font-size: 0.5rem;
}

.centered {
  text-align: center;
}

.grandmaster-names-block {
  font-size: 0.9rem;
  margin-top: 15px;
  margin-bottom: 20px;
  max-width: 80%;

}

.grandmaster-names-block a {
  color: purple;
  text-decoration: underline 
}

.grandmaster-names-block a:hover {
  text-decoration: underline dashed
}

.nameText {
  font-size: 0.9rem;
}

.nameDescriptionText {
  font-size: 0.7rem;
}

.summaryText {
  font-size: 1rem;  /* Adjust this value as you see fit */
}

.summaryChart {
  max-width: 100%; 
  margin-top: 0px;
  padding-top: 0px;
}

.dotted-underline {
  text-decoration: underline dotted;
}

.dotted-underline:hover {
  text-decoration: underline dashed;
}

.opening-highlight-text {
  font-weight: bold;
  color: purple;
  font-size: 1.1rem;
  padding-bottom: 5px;
  display: inline-block;
  text-decoration: underline;

}

.stamp-image-container {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 0px;
}

.stamp-image-container-dialog {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 0px;
}

.stamp-image-dialog {
  width: 150px;
  height: auto;
  border: none;
}

.header-tooltip-icon {
  display: flex;
  align-items: center;
}

.tooltip-content {
  text-align: left;
  font-size: 1rem;
}

.stamp-image {
  width: 180px;
  height: auto;
  border: none;
}

.tooltip-text {
  color: #222;
  font-size: .75em;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.progress-wrapper {
  width: 40%;
  margin: 0 auto; 
  padding: 5px;
  display: flex;
  flex-direction: column;  
  background-color: white;
  border-radius: 20px;
  border-color: #2074d4;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
}

@media (max-width: 700px) {
  .progress-wrapper {
    width: 90%;

  }
}

.progress-cancel-button {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-additional-text {
  margin-top: 10px;
  margin-bottom: 0px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  text-align: center;
  line-height: 1; 
}

.progress-bar-subtitle {
  font-size: 0.8em;
  padding-bottom: 12px;
  text-align: center;
  max-width: 80%;

}

.progress-bar-container {
  display: flex;
  position: relative;
  width: 90%;
  height: 40px;
  background-color: #e0e0e0;
  border-color: black;
  border-radius: 5px;
  overflow: hidden;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.2); 

}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #4caf50;
  border-color: black;
  
}

.progress-text {
  position: absolute; 
  width: 100%;
  text-align: center;
  top: 50%; 
  transform: translateY(-50%); 
  font-weight: bold;
}

.chess-piece {
  width: 20px; 
  height: auto;
  margin-left: 0px; 
  margin-bottom: 0px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.intro-container {
  margin: auto;
  margin-bottom: 0px;
  max-width: 40%;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .intro-container {
    max-width: 90%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

.header-alert-box {
  margin: 20px auto;
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 700px) {
  .header-alert-box {
    max-width: 85%;
  }
}

.results-summary-parent-container {
  background-color: #f9f9f9;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.results-summary-container {
  margin: auto;
  margin-top: px;
  margin-bottom: 0px;
  max-width: 40%;
  /* background-color: #B4C5E4; */
  color: black;

}

@media (max-width: 700px) {
  .results-summary-container {
    max-width: 85%;

  }
}

.results-summary-container h2 {
  margin-top: 0px;
  margin-bottom: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: center;
  color: #2074d4;
  text-decoration: underline;
}

.grid-outer-container {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  /* background-color: #B4C5E4; */
  color: black;
  max-width: 70%;
}

@media (max-width: 700px) {
  .grid-outer-container {
    grid-template-columns: 1fr;
    max-width: 90%;

  }
}

.lower-container h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 5px;
  text-align: center;
  color: #2074d4;
  text-decoration: underline;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  max-width: 100%;
  margin: auto;  
}

@media (max-width: 700px) {
  .grid-container {
    grid-template-columns: 1fr;
    max-width: 100%;

  }
}

.row {
  display: grid;
  grid-template-areas: 
    "label label"
    "chessboard text";
  grid-template-columns: 1fr 3fr;
  margin: 0px 5px; 
  margin-bottom: 10px;
  padding: 0px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

@media (max-width: 700px) {
  .row {
    grid-template-areas: 
      "label"
      "chessboard"
      "text";
    grid-template-columns: 1fr;
    justify-items: center; 


  }
}

.row-body {
  display: flex;
}

.label-row {
  grid-area: label;
  font-family: 'M PLUS Rounded 1c', Arial, sans-serif;
  font-size: 1em;
  color: #333;
  align-items: center;
  justify-content: center;
  background-color: #3A75C4;
  border: 1px solid #3A75C4; 
  color: white;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label-description {
  text-transform: lowercase;
  font-size: 0.7em;
  font-weight: bold;
  font-family: Arial, sans-serif;
  margin-bottom: 3px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 0px;
  text-align: center;

}

.chess-image {
  grid-area: chessboard;
  min-width: 200px;
  max-width: 250px;
  max-height: 250px;
  padding: 5px;
  box-sizing: border-box;
  align-self: start; 
}

.text {
  grid-area: text;
  width: 90%;
  padding: 10px;
  color: #111;
  align-self: start; 
}

.link {
  text-decoration: none;
  color: #000;
}

.rarity {
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 5px;
}


.all-openings-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
  max-width: 70%;
  margin: auto;
}

.all-openings-grid-item {
  display: flex;
  align-items: center;
  justify-content: center; 
  margin: 0px 5px; 
  margin-bottom: 10px;
  padding: 0px;
  border: 1px solid gray; 
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0.9em;
  color: black;
}

.all-openings-grid-item a {
  color: inherit; 
  text-decoration: none; 
}

.all-openings-grid-item a:hover,
.all-openings-grid-item a:active,
.all-openings-grid-item a:focus {
  color: inherit; 
  text-decoration: underline; 
}

.all-openings-intro-container {
  margin-top: 0px;
  margin-bottom: 20px;
  text-align: center;
}

.all-openings-intro-subtitle {
  font-size: 0.9em;
  text-align: center;
  font-style: italic;
}


@media (max-width: 700px) {
  .all-openings-grid-container {
    grid-template-columns: 1fr;
    max-width: 90%;
  }
}

.colored-grid-item {
  background-color: lightgreen; 
}

#chessImageContainer {
  position: fixed;
  width: 200px; 
  height: 200px; 
  z-index: 1;
  pointer-events: none; /* This will prevent the div from blocking mouse events */
}


.show-all-openings-button-container {
  margin: 0em;
  padding: 1em;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  gap: 30px; 
}

.footer-text {
  font-size: 0.8em;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 20px;
  color: rgb(109, 109, 109);
}

.footer-text a {
  color: rgb(109, 109, 109);
  text-decoration: underline ;
}

.dialog-title h2 {
  text-align: center;
  font-family: 'Lato', Arial, sans-serif;
  font-size: 120%;
  color: #2074d4;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  
}

.dialog-content {
  margin-top: -7px; 
  margin-bottom:10px;
  max-width: 90%;
  
}

.dialog-copyable-text {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f4f4f4;
  color: #444;
  font-size: 90%;
}

.dialog-copyable-text a {
  color: #444;
  text-decoration: underline;
}


.dialog-copyable-text-url {
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #f4f4f4;
  font-size: 90%;

}

.dialog-copyable-text-url a {
  color: #444; 
  text-decoration: none;
}


.dialog-explanatory-text {
  text-align: center;
  margin-bottom: 6px;
}

